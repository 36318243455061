import { Box, Button, Stack, Typography } from "@mui/joy"
import { grey, orange } from "@mui/material/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons"
import { CardMedia } from "@mui/material"
import RegionZone from "./RegionZone"
import { IMAGE } from "../../../constant"

const ZoneCible = () => {
    return (
        <Stack
            gap={2}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
            >
                <Typography
                    level="h3"
                    textColor={orange[700]}
                    sx={{
                        textWrap: "nowrap"
                    }}
                >
                    Zones cibles
                </Typography>
                <Box
                    height={2}
                    width={"100%"}
                    bgcolor={orange[700]}
                />
            </Stack>

            <Stack
                direction={"row"}
                flexWrap={window.innerWidth < 900 ? "wrap" : "nowrap"}
                gap={2}
                alignItems={"center"}
                bgcolor={grey[200]}
                p={3}
                borderRadius={12}
            >
                <Stack
                    gap={2}
                    sx={{
                        overflowX: "hidden"
                    }}
                >
                    <Typography
                        textAlign={"justify"}
                    >
                        Le projet ciblera d’abord
                        <b> 87 communes </b>
                        sélectionnées sur la base de critères liés à la consolidation/synergie avec les projets en cours de la Banque (principalement PREEFN et PGRNCC); la zone d’intervention de la GMV au Mali; le profil de pauvreté de la commune ;25 et la vulnérabilité de l’écosystème. D’autres communes, y compris dans d’autres régions, peuvent être ajoutées en utilisant les mêmes critères. Parmi les 87 communes ciblées, 53 se trouvent dans des zones de prévention (39 d’entre elles présentent un risque de conflit et 10 un risque d’intensité moyenne).
                    </Typography>

                    <CardMedia
                        component={"img"}
                        src={IMAGE.carteDuMali}
                        sx={{
                            width: 400,
                            alignSelf: "center"
                        }}
                    />

                    <Button
                        endDecorator={
                            <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        }
                        sx={{
                            bgcolor: orange[700],
                        }}
                    >
                        Cliquez ici pour cartographié les différentes régions
                    </Button>
                </Stack>

                <RegionZone />
            </Stack>

        </Stack>
    )
}

export default ZoneCible