import { LOCATILTE_T, SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T } from "../../types";
import { localite as fetchLocalites } from "../API/localite"; // Renommer pour éviter le conflit
import { suivi_annuel_sur_des_indicateurs_referentiels_par_localité } from "../API/suivi_annuel_sur_des_indicateurs_referentiels_par_localité";

export const region_tab = async (
  titre: string,
  annee?: number,
  codeIndicateur?: string,
  indicateurListe: SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T[] | undefined = undefined,
  localiteListe: LOCATILTE_T[] | undefined = undefined
): Promise<{ titre: string; resultats: any[] }> => {
  try {
    const indicateurs = indicateurListe
      || await suivi_annuel_sur_des_indicateurs_referentiels_par_localité();

    const localites = localiteListe
      || await fetchLocalites("Region");

    const resultats: any[] = [];
    const codesML = new Set<string>();

    const indicateurFiltre = indicateurs?.find((indicateur: any) => indicateur.Code == codeIndicateur);

    if (indicateurFiltre) {
      const valeursRealisees = indicateurFiltre?.Valeur_Realisée;

      titre = indicateurFiltre.Intitule;

      if (valeursRealisees && typeof valeursRealisees === 'object') {
        // Parcourir chaque code localité principal (ex: 2024, 2030, etc.)
        Object.keys(valeursRealisees).forEach((codeLocalite) => {
          const sousLocalites = valeursRealisees[codeLocalite];

          // Si sousLocalites est un objet, il contient des sous-localités (MLxx)
          if (typeof sousLocalites === 'object') {
            // Parcourir chaque sous-localité (ML01, ML02, etc.)
            Object.keys(sousLocalites).forEach((sousCodeLocalite) => {
              const valeurRealisee = sousLocalites[sousCodeLocalite];

              const currentLocalite = localites?.find((localite: any) => localite.code === sousCodeLocalite);

              resultats.push({
                codeLocalite: sousCodeLocalite,
                nomRegion: currentLocalite?.nom || "--",
                valeurRealisee: isNaN(valeurRealisee) ? "--" : valeurRealisee,
                latitute: currentLocalite?.latitude || null,
                longitude: currentLocalite?.longitude || null
              });

              // Stocker les codes dans le set pour éviter les doublons
              codesML.add(sousCodeLocalite);
            });
          } else {
            console.warn(`Aucune sous-localité trouvée pour le code localité principal ${codeLocalite}`);
          }
        });
      } else {
        console.warn(`Aucune valeur réalisée pour l'indicateur ${indicateurFiltre.Code}`);
      }
    } else {
      console.warn(`Aucun indicateur trouvé avec le code : ${codeIndicateur}`);
    }

    console.group(`${titre} Indicateur par region.`, resultats);
    console.groupEnd();

    return { titre, resultats };
  } catch (error) {
    console.error("Erreur lors de la récupération des régions :", error);
    throw error;
  }
};