import { faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box } from '@mui/joy'
import { green } from '@mui/material/colors'

const Item = ({ text }: { text: string }) => {
    return (
        <Box>
            <FontAwesomeIcon
                icon={faDotCircle}
                style={{
                    marginRight: 10
                }}
                color={green[800]}
            />
            {
                text
            }
        </Box>
    )
}

export default Item