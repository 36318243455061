import { faAngleDown, faMapLocation } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, ButtonGroup, Typography } from "@mui/joy"
import { Collapse } from "@mui/material"
import { useState } from "react"
import { MENU_ITEM } from "../../constant"
import { green, grey } from "@mui/material/colors"

const List = (
    {
        item,
        currentIndex,
        setcurrentIndex
    }:
        {
            item: typeof MENU_ITEM[0],
            currentIndex: string,
            setcurrentIndex: any
        }
) => {
    const [isShow, setisShow] = useState(true);

    return (
        <>
            <Button
                variant="outlined"
                color="success"
                sx={{
                    border: 0,
                    p: 0,
                    ":hover": {
                        bgcolor: "transparent"
                    }
                }}
                endDecorator={
                    <FontAwesomeIcon
                        icon={faAngleDown}
                        style={{
                            transition: `all 0.4s`,
                            transform: `rotate(${isShow ? 0 : 180}deg)`,
                        }}
                    />
                }
                startDecorator={
                    <FontAwesomeIcon icon={faMapLocation} />
                }
                onClick={() => {
                    setisShow(!isShow);
                }}
            >
                <Typography
                    width={"100%"}
                    textAlign={"left"}
                >{item.title}</Typography>
            </Button>

            <Collapse
                in={isShow}
                unmountOnExit
            >
                <ButtonGroup
                    orientation="vertical"
                    variant="solid"
                    sx={{
                        borderRadius: 12,
                        bgcolor: "white",
                    }}
                >
                    {
                        item.item.map((value, index) => {
                            const elementIndex = `${item.title}-${index}`;
                            return (
                                <Button
                                    variant={currentIndex === elementIndex ? "solid" : "outlined"}
                                    size="lg"
                                    sx={{
                                        px: 1,
                                        bgcolor: currentIndex === elementIndex ? green[800] : grey[600],
                                        ":hover": {
                                            bgcolor: green[900],
                                        }
                                    }}
                                    onClick={() => {
                                        setcurrentIndex && setcurrentIndex(elementIndex);
                                    }}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            textAlign: "left",
                                        }}
                                        textColor={"white"}
                                    >
                                        {value.nom}
                                    </Typography>
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>
            </Collapse >
        </>
    )
}

export default List