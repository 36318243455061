import { Stack, Typography } from "@mui/joy"
import { green, orange } from "@mui/material/colors"
import { useMemo } from "react"

const RealisationZone = (
    { valeurRealise, valeurTotal }:
        {
            valeurRealise: number,
            valeurTotal: number
        }
) => {
    const calculate = useMemo(() => () => {
        let result: {
            percentageToShow: number,
            width: number | 'fit-content',
        } = {
            percentageToShow: 0,
            width: 'fit-content'
        };

        if (valeurTotal == 0) {
            result.percentageToShow = 1 * 100;
            result.width = 275;
        }
        else {
            let ratio = valeurRealise / valeurTotal;
            result.percentageToShow = (ratio * 100) % 100 == 0
                ? ratio * 100
                : parseFloat((ratio * 100).toFixed(2));

            result.width = ratio > 1 ? 275
                : ratio <= 0.1
                    ? 'fit-content'
                    : ratio * 250;
        }

        return result;
    }, [valeurRealise, valeurTotal]);

    return (
        <Stack
            gap={1}
            fontWeight={700}
        >
            <Stack
                gap={1}
            >
                <Typography textColor={green[800]}>
                    Valeur cible
                </Typography>

                <Stack
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Stack
                        bgcolor={green[500]}
                        p={0.5}
                        borderRadius={330}
                        textAlign={"center"}
                        border={`3px solid ${green[800]}`}
                        width={250}
                    >
                        100 %
                    </Stack>
                    <Typography
                        textColor={green[900]}
                    >
                        {valeurTotal.toLocaleString()}
                    </Typography>
                </Stack>
            </Stack>

            <Stack
                gap={1}
            >
                <Stack
                    direction={"row"}
                    gap={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                >
                    <Stack
                        bgcolor={orange[500]}
                        p={0.5}
                        borderRadius={330}
                        textAlign={"center"}
                        border={`3px solid ${orange[900]}`}
                        width={
                            calculate().width
                        }
                        color={'white'}
                    >

                        {
                            calculate().percentageToShow.toLocaleString()
                        } %
                    </Stack>
                    <Typography
                        textColor={orange[900]}
                    >
                        {valeurRealise.toLocaleString()}
                    </Typography>
                </Stack>

                <Typography textColor={orange[800]}>
                    Réalisation
                </Typography>
            </Stack>
        </Stack >
    )
}

export default RealisationZone