import { Box, Button, Stack } from "@mui/joy";
import { grey, orange } from "@mui/material/colors";
import { SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T } from "../../../../types";

const IndicateurItem = (
    { value, setcurrentIndicateur, isActive = false }:
        {
            value: SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T,
            setcurrentIndicateur: any,
            isActive: boolean
        }
) => {
    return (
        <Stack
            direction={'row'}
            alignItems={'center'}
            onClick={() => {
                setcurrentIndicateur && setcurrentIndicateur(value)
            }}
        >
            {
                isActive && (
                    <Box
                        sx={{
                            width: 10,
                            height: 20,
                            bgcolor: orange[800],
                            borderRadius: 50,
                            mr: '-5px',
                            zIndex: 1
                        }}
                    />
                )
            }
            <Button
                sx={{
                    bgcolor: grey[100],
                    color: 'black',
                    fontSize: 11,
                    textAlign: 'left'
                }}
                fullWidth={true}
            >{value.Intitule}</Button>
        </Stack>
    )
}

export default IndicateurItem