import Router from "./Routes"
import "./assets/css/leaflet.css"
import "@fontsource/ubuntu"

const App = () => {
  return (
    <Router />
  )
}

export default App