import { Box, Button, Stack, Typography } from "@mui/joy"
import { green, grey, orange } from "@mui/material/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons"
import Composante from "./Composante"
import GrapheZone from "./GrapheZone"

const PresentationZone = () => {
    return (
        <Stack
            gap={2}
            id="presentation"
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
            >
                <Typography
                    level="h3"
                    textColor={orange[700]}
                    sx={{
                        textWrap: "nowrap"
                    }}
                >
                    Presentation du projet
                </Typography>
                <Box
                    height={2}
                    width={"100%"}
                    bgcolor={orange[700]}
                />
            </Stack>

            <Stack
                direction={"row"}
                gap={2}
                alignItems={"center"}
                bgcolor={grey[200]}
                p={3}
                borderRadius={12}
            >
                <Box
                    sx={{
                        width: 400,
                        aspectRatio: "1/1",
                        position: "absolute",
                        bgcolor: orange[700],
                        zIndex: -1,
                        ml: '-250px',
                        mt: '150px',
                        borderRadius: 400
                    }}
                />
                <Stack
                    gap={2}
                >
                    <Stack
                        gap={2}
                    >
                        <Typography
                            textAlign={"justify"}
                        >
                            Entièrement financé à hauteur de
                            <b> 90 milliards FCFA par la Banque mondiale, </b>
                            le projet restauration  des terres dégradées au Mali a pour objectif
                            <b> d’accroître l’adoption de pratiques de restauration des paysages intelligentes face au climat et l’accès à des opportunités de revenus. </b>
                            Le projet comprend quatre composantes. A savoir:
                        </Typography>

                        <Composante
                            title="Composant 1"
                            desc="La première étant le soutien institutionnel et le renforcement des capacités pour la restauration des paysages et la résilience climatique."
                        />
                        <Composante
                            title="Composant 2"
                            desc="Le deuxième volet concerne les investissements dans la restauration des paysages et la résilience des communautés aux impacts climatiques."
                        />
                        <Composante
                            title="Composant 3"
                            desc="La troisième composante est la coordination, le suivi et l’évaluation."
                        />
                    </Stack>

                    <Button
                        endDecorator={
                            <FontAwesomeIcon icon={faArrowAltCircleRight} />
                        }
                        sx={{
                            bgcolor: orange[700],
                        }}
                    >
                        Cliquez ici pour voir plus d'informations sur le projet PRTD
                    </Button>
                </Stack>

                {
                    window.innerWidth > 900 && <GrapheZone />
                }

            </Stack>
        </Stack>
    )
}

export default PresentationZone