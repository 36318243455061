import { Card, CardContent, CardCover, Container, Stack, Typography } from "@mui/joy"
import { CardMedia } from "@mui/material"
import { green } from "@mui/material/colors"
import { IMAGE } from "../../constant"
import LogoZone from "./LogoZone"
import ContactZone from "./ContactZone"
import LienUtileZone from "./LienUtileZone"

const Footer = () => {
    return (
        <Card
            sx={{
                border: 0,
                borderRadius: 0,
                borderTop: `1px solid ${green[700]}`,
                mt: 10,
                color: "white",
                p: 0,
                pt: 2
            }}
        >
            <CardCover>
                <CardMedia
                    src={IMAGE.fondTerre}
                    component={"img"}
                />
            </CardCover>
            <CardCover
                sx={{
                    bgcolor: "black",
                    opacity: 0.3
                }}
            />
            <CardContent>
                <Container>
                    <Stack
                        direction="row"
                        justifyContent={"space-between"}
                        gap={window.innerWidth < 500 ? 5 :2}
                        flexWrap={window.innerWidth < 500 ? 'wrap' : 'nowrap'}
                        sx={{
                            "& > *":{
                                width: '100%'
                            }
                        }}
                    >
                        <LogoZone />
                        <ContactZone />
                        <LienUtileZone />
                    </Stack>
                </Container>
                <Stack
                    p={2}
                    mt={2}
                    bgcolor={green[800]}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                    gap={1}
                >
                    <CardMedia
                        component={"img"}
                        src={IMAGE.drapeauDuMali}
                        sx={{
                            width: 20
                        }}
                    />
                    <Typography textColor={"white"} fontWeight={700}>
                        Copyright©2024 Projet PRTD-Mali
                    </Typography>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default Footer