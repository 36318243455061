import { BrowserRouter } from 'react-router-dom';
import NavBar from '../components/NavBar';
import Accueil from '../pages/accueil';
import { useEffect, useState } from 'react';
import PlainNavBar from '../components/NavBar/PlainNavBar';
import Cartographie from '../pages/Cartographie';
import Informations from '../pages/Informations';
import Footer from '../components/Footer';

const Router = () => {
    const [showNavBar, setshowNavBar] = useState(false);
    const [currentPath, setcurrentPath] = useState(window.location.pathname.substring(1));
    const [screenToShow, setscreenToShow] = useState(<Accueil />);
    useEffect(
        () => {
            switch (currentPath) {
                case 'Accueil':
                    setscreenToShow(<Accueil />);
                    break;
                case 'Cartographie':
                    setscreenToShow(<Cartographie />);
                    break;
                case 'Informations':
                    setscreenToShow(<Informations />);
                    break;
                default:
                    setcurrentPath('Accueil');
            }
        },
        [currentPath]
    )

    return (
        <BrowserRouter>
            <NavBar
                showNavBar={showNavBar}
                setshowNavBar={setshowNavBar}
                currentPath={currentPath as any}
                setcurrentPath={setcurrentPath}
            />

            <PlainNavBar
                setshowNavBar={setshowNavBar}
                setcurrentPath={setcurrentPath}
                currentPath={currentPath}
            />

            {screenToShow}
            <Footer />
        </BrowserRouter>
    )
}

export default Router