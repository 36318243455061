import { REGION_TAB_T } from '../../../types';
import CustomeGraph from '../../../components/CustomeGraph';

const GrapheZone = (
  {
    data
  }:
    {
      data: REGION_TAB_T[]
    }
) => {
  return (
    <CustomeGraph
      type='bar'
      xLabel={data.map(value => value.nomRegion)}
      datas={[
        {
          label: "Valeurs réalisées",
          value: data.map(value => value.valeurRealisee),
          bgColor: undefined
        }
      ]}
    />
  );
};

export default GrapheZone;
