import { faDotCircle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, ButtonGroup, Stack, Typography } from "@mui/joy"
import { Drawer } from "@mui/material"
import { green, grey } from "@mui/material/colors"
import { useState } from "react"
import List from "./List"
import { MENU_ITEM, PAGE_URL } from "../../constant"
import CloseBtn from "./CloseBtn"

const NavBar = (
    {
        showNavBar, setshowNavBar, currentPath, setcurrentPath
    }:
        {
            showNavBar: boolean,
            setshowNavBar: any,
            currentPath: "Informations" | "Accueil" | "/" | "Cartographie",
            setcurrentPath: any
        }

) => {
    const [currentIndex, setcurrentIndex] = useState(`${MENU_ITEM[0].title}-0`);

    return (
        <Drawer
            open={showNavBar}
            anchor="right"
            PaperProps={{
                sx: {
                    bgcolor: grey[100]
                }
            }}
            onClose={() => setshowNavBar && setshowNavBar(false)}
        >
            <Stack
                sx={{
                    p: 1,
                    gap: 2,
                    width: 300
                }}
            >
                <CloseBtn
                    setshowNavBar={setshowNavBar}
                    sx={{
                        alignSelf: 'flex-end',
                    }}
                    showNavBar={false}
                />
                <Stack
                    gap={1}
                    sx={{
                        "& > button": {
                            borderRadius: 12,
                            color: "black",
                        }
                    }}
                >
                    {
                        MENU_ITEM.map((value, index) => (
                            <List
                                key={index}
                                item={value}
                                currentIndex={currentIndex}
                                setcurrentIndex={setcurrentIndex}
                            />
                        ))
                    }
                </Stack>

                <ButtonGroup
                    orientation="vertical"
                    variant="solid"
                    sx={{
                        borderRadius: 12,
                        bgcolor: "white",
                    }}
                >
                    {
                        PAGE_URL.map((value, index) => {
                            return (
                                <Button
                                    variant={value.nom == currentPath ? "solid" : "outlined"}
                                    size="lg"
                                    sx={{
                                        px: 1,
                                        bgcolor: value.nom == currentPath ? green[800] : grey[600],
                                        ":hover": {
                                            bgcolor: green[900],
                                        }
                                    }}
                                    onClick={() => {
                                        setcurrentPath(`${value.nom}`);
                                    }}
                                    key={index}
                                >
                                    <Typography
                                        sx={{
                                            width: "100%",
                                            textAlign: "left",
                                        }}
                                        textColor={"white"}
                                    >
                                        {value.nom}
                                    </Typography>
                                </Button>
                            )
                        })
                    }
                </ButtonGroup>

            </Stack>
        </Drawer>
    )
}

export default NavBar