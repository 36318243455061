import axios from "axios";
import { LOCATILTE_T, TYPE_LOCALITE_T } from "../../types";

export const localite = async (type: TYPE_LOCALITE_T = "Region"): Promise<LOCATILTE_T[] | null> => {
    try {
        const { data } = await axios.get(`${process.env.REACT_APP_API_URL}/localites.php?localite=${type}`);
        return data?.Regions;
    }
    catch {
        return null;
    }
}