import { Stack } from "@mui/joy"
import { CardMedia } from "@mui/material"

const Cartographie = () => {

    return (
        <CardMedia
            component={'iframe'}
            src="https://sise.prtd-mali.ml/fier2RucheMap/index.html"
            sx={{
                border: 0,
                height: 'calc(100vh - 65px)',
                mt: '65px'
            }}
        />
    )
}

export default Cartographie