import { Avatar, Box, Card, CardContent, Stack, Typography } from "@mui/joy"
import { green } from "@mui/material/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowUpRightDots, faBoxesStacked, faChartLine, faTree } from "@fortawesome/free-solid-svg-icons"

const GrapheZone = () => {
    return (
        <Stack
            ml={"80px"}
            mt={'50px'}
        >
            <Box
                sx={{
                    aspectRatio: "1/1",
                    width: 300,
                    border: `10px solid ${green[900]}`,
                    borderRadius: 300,
                    position: "absolute"
                }}
            />
            <Stack>
                <Card
                    sx={{
                        p: 1,
                        width: 230
                    }}
                >
                    <CardContent
                        sx={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <Avatar
                            size="lg"
                        >
                            <FontAwesomeIcon icon={faTree} />
                        </Avatar>
                        <Stack>
                            <Typography
                                level="body-xs"
                                fontWeight={700}
                            >
                                Restauration des paysages et des services écosystémiques
                            </Typography>
                            <Typography
                                level="title-lg"
                            >
                                70 millions $USD
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        p: 1,
                        width: 230,
                        ml: "200px",
                        mt: 5
                    }}
                >
                    <CardContent
                        sx={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <Avatar
                            size="lg"
                        >
                            <FontAwesomeIcon icon={faChartLine} />
                        </Avatar>
                        <Stack>
                            <Typography
                                level="body-xs"
                                fontWeight={700}
                            >
                                Planification et développement territorial
                            </Typography>
                            <Typography
                                level="title-lg"
                            >
                                7 millions $USD
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>

            <Stack
                mt={"-80px"}
                ml={"-80px"}
            >
                <Card
                    sx={{
                        p: 1,
                        width: 230
                    }}
                >
                    <CardContent
                        sx={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <Avatar
                            size="lg"
                        >
                            <FontAwesomeIcon icon={faArrowUpRightDots} />
                        </Avatar>
                        <Stack>
                            <Typography
                                level="body-xs"
                                fontWeight={700}
                            >
                                Améliorer la résilience des moyens de subsistance locaux
                            </Typography>
                            <Typography
                                level="title-lg"
                            >
                                50 million $USD
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
                <Card
                    sx={{
                        p: 1,
                        width: 230,
                        ml: "200px",
                        mt: 5
                    }}
                >
                    <CardContent
                        sx={{
                            flexDirection: "row",
                            alignItems: "center"
                        }}
                    >
                        <Avatar
                            size="lg"
                        >
                            <FontAwesomeIcon icon={faBoxesStacked} />
                        </Avatar>
                        <Stack>
                            <Typography
                                level="body-xs"
                                fontWeight={700}
                            >
                                Coordination, gestion de projet et communication
                            </Typography>
                            <Typography
                                level="title-lg"
                            >
                                10 million $USD
                            </Typography>
                        </Stack>
                    </CardContent>
                </Card>
            </Stack>

        </Stack>
    )
}

export default GrapheZone