import { Avatar, Box, Card, CardContent, CardCover, Stack, Typography } from '@mui/joy'
import { IMAGE } from '../../constant'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDoubleDown } from '@fortawesome/free-solid-svg-icons'
import LogoZone from '../Footer/LogoZone'

const Header = () => {
  return (
    <Card
      sx={{
        height: "100vh",
        p: 0,
        border: 0,
        borderRadius: 0
      }}
    >
      <CardCover>
        <img
          src={IMAGE.fondTerre}
        />
      </CardCover>
      <CardCover
        sx={{
          boxShadow: `inset 0px -400px 100px 30px black`,
          opacity: 0.7
        }}
      />

      <CardContent>
        <Stack
          justifyContent={"center"}
          height={"100%"}
          alignItems={"center"}
        >
          <Box
            mt={-20}
          >
            <Typography
              level='h1'
              textColor={"white"}
              fontSize={110}
            >
              PRTD
            </Typography>
            <Typography
              level='body-sm'
              textColor={"white"}
              mb={3}
            >
              Projet de Restauration des Terres Dégradées
            </Typography>
          </Box>
        </Stack>

        <Stack
          p={2}
          position={"absolute"}
          bottom={0}
          left={0}
          right={0}
          gap={3}
          alignItems={"center"}
        >
          <Typography
            textColor={"white"}
            textAlign={"center"}
            fontSize={30}
          >
            « Restaurons les ressources, rendons-leur vie ; Dans ce combat, chacun a son rôle à remplir. »
          </Typography>
          <Avatar
            size='lg'
            sx={{
              alignSelf: "center",
              ":hover": {
                animationName: null
              }
            }}
            className={'levitation'}
          >
            <FontAwesomeIcon icon={faAngleDoubleDown} />
          </Avatar>
        </Stack>
      </CardContent>
    </Card >
  )
}

export default Header