import { Chart as ChartJS, BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement } from 'chart.js';
import { Bar, Doughnut, Line } from 'react-chartjs-2';
import { getRandomColor } from '../../functions/scripts/getRandomColor';

ChartJS.register(BarElement, LineElement, PointElement, CategoryScale, LinearScale, Title, Tooltip, Legend, ArcElement);

const CustomeGraph = ({
    xLabel,
    datas,
    type = "bar",
}:
    {
        xLabel: string[],
        datas: {
            label: string,
            value: number[],
            bgColor: string | undefined
        }[],
        type: "line" | "bar" | "donut",
    }) => {
    const data = {
        labels: xLabel.map(value => value),
        datasets: datas.map(value => ({
            label: value.label,
            data: value.value,
            backgroundColor: value.bgColor || getRandomColor(),
            // borderColor: value.bgColor || getRandomColor(),
            fill: true,
            tension: type == "line" ? 0.3 : 0,
        })),
    };

    const option = {
        responsive: true,
        scales: {
            y: {
                beginAtZero: true,
            },
        },
    };

    const styleTmp = {
        width: "100%",
        maxWidth: 700,
        height: window.innerWidth < 800 ? 500 : 350,
    };

    return (
        type == "bar"
            ? <Bar data={data} options={option} style={styleTmp} />
            : type == "line"
                ? <Line data={data} options={option} style={styleTmp} />
                : <Doughnut data={data} options={option} style={styleTmp} />
    );
}

export default CustomeGraph;
