import { Container } from "@mui/joy"
import IndicateurZone from "./IndicateurZone"

const Informations = () => {
    return (
        <Container
            sx={{
                mt: '65px'
            }}
        >
            <IndicateurZone />

        </Container>
    )
}

export default Informations