import axios from "axios";
import { SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T, TYPE_LOCALITE_T } from "../../types";


// Fonction pour récupérer les localités à partir du code retour
export const suivi_annuel_sur_des_indicateurs_referentiels_par_localité = (
  type: TYPE_LOCALITE_T = 'Region'
): Promise<SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T[]> => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/suivi_indicateurs_localite.php?localite=${type}`)
      .then(({ data }) => {
        // Extraire les localités à partir des données
        const localites = data?.indicateurs?.map((indicateur: any) => ({
          codeLocalite: indicateur?.code_localite,  // supposer que code_localite existe dans les données
          nomLocalite: indicateur?.nom_localite,    // supposer que nom_localite existe dans les données
        }));

        resolve(data?.indicateurs as SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T[]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
