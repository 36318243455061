import { Button } from '@mui/joy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDotCircle } from '@fortawesome/free-solid-svg-icons'
import { green } from '@mui/material/colors'

const CloseBtn = ({ setshowNavBar, showNavBar, sx, }: { setshowNavBar: any, sx: any, showNavBar: boolean | null }) => {
    return (
        <Button
            variant='solid'
            endDecorator={
                <FontAwesomeIcon icon={faDotCircle} />
            }
            sx={[
                {
                    borderRadius: 12,
                    bgcolor: green[800]
                },
                sx
            ]}
            onClick={() => setshowNavBar && setshowNavBar(showNavBar)}
        >
            Menu
        </Button>
    )
}

export default CloseBtn