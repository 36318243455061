import { faTelegramPlane } from "@fortawesome/free-brands-svg-icons"
import { faComment, faEnvelopeOpen, faMailBulk, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Avatar, Button, Divider, Input, Link, Stack, Typography } from "@mui/joy"
import { orange } from "@mui/material/colors"

const ContactZone = () => {
    return (
        <Stack
            gap={2}
        >
            <Typography
                fontWeight={700}
                textColor={"white"}
                fontSize={20}
            >
                Contactez nous
            </Typography>
            <form>
                <Stack
                    gap={1}
                >
                    <Input
                        placeholder="Votre adresse mail"
                        endDecorator={
                            <FontAwesomeIcon color={orange[800]} icon={faMailBulk} size={"xs"} />
                        }
                    />
                    <Input
                        placeholder="Votre message"
                        endDecorator={
                            <FontAwesomeIcon color={orange[800]} icon={faComment} size={"xs"} />
                        }
                    />
                    <Stack
                        direction={"row"}
                        gap={1}
                    >
                        <Button
                            variant="outlined"
                            sx={{
                                fontWeight: 700,
                                color: 'white',
                                border: `2px solid white`
                            }}
                        >Reset</Button>
                        <Button
                            fullWidth
                            endDecorator={
                                <FontAwesomeIcon icon={faTelegramPlane} />
                            }
                        >Envoyer</Button>
                    </Stack>
                </Stack>
            </form>

            <Stack
                sx={{
                    "& > a": {
                        color: "white",
                        fontWeight: 700
                    }
                }}
                gap={1}
            >
                <Link
                    startDecorator={
                        <Avatar
                            size="sm"
                        >
                            <FontAwesomeIcon icon={faPhone} />
                        </Avatar>
                    }
                    href="tel:+22344907031"
                >(+223) 44 90 70 31</Link>

                <Divider sx={{ bgcolor: "white" }} />

                <Link
                    startDecorator={
                        <Avatar
                            size="sm"
                        >
                            <FontAwesomeIcon icon={faPhone} />
                        </Avatar>
                    }
                    href="tel:+22344907032"
                >MGP: (+223) 44 90 70 32</Link>

                <Divider sx={{ bgcolor: "white" }} />

                <Link
                    startDecorator={
                        <Avatar
                            size="sm"
                        >
                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                        </Avatar>
                    }
                    href="maito:prtd@prtd-mali.org"
                >prtd@prtd-mali.org</Link>
            </Stack>
        </Stack>
    )
}

export default ContactZone