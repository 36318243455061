import { Card, Stack } from "@mui/joy"
import { CardMedia } from "@mui/material"
import { orange } from "@mui/material/colors"

const RegionZone = () => {
    return (
        <Stack
            width={window.innerWidth > 800 ? 360 : "initial"}
            direction={"row"}
            flexWrap={"wrap"}
            justifyContent={"space-between"}
            gap={2}
            sx={{
                ">*": {
                    maxWidth: window.innerWidth > 800 ? 150 : 110,
                    justifyContent: "center",
                    borderColor: orange[700]
                }
            }}
            minWidth={window.innerWidth > 800 ? 400 : "initial"}
        >
            <Card>
                <CardMedia
                    component={"img"}
                    src={"/assets/images/region/segou.png"}
                />
            </Card>
            <Card>
                <CardMedia
                    component={"img"}
                    src={"/assets/images/region/koulikoro.png"}
                />
            </Card>
            <Card>
                <CardMedia
                    component={"img"}
                    src={"/assets/images/region/mopti.png"}
                />
            </Card>
            <Card>
                <CardMedia
                    component={"img"}
                    src={"/assets/images/region/kayes.png"}
                />
            </Card>
        </Stack >
    )
}

export default RegionZone