import { faArrowAltCircleRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Box, Button, Divider, Stack, Typography } from '@mui/joy'
import { green, grey, orange } from '@mui/material/colors'
import Item from './Item'

const ResultatZone = () => {
    return (
        <Stack
            gap={2}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
            >
                <Typography
                    level="h3"
                    textColor={orange[700]}
                    sx={{
                        textWrap: "nowrap"
                    }}
                >
                    Résultats du  PRTD-MALI
                </Typography>
                <Box
                    height={2}
                    width={"100%"}
                    bgcolor={orange[700]}
                />
            </Stack>

            <Stack
                gap={2}
                bgcolor={grey[200]}
                p={3}
                borderRadius={12}
                sx={{
                    overflowX: "hidden"
                }}
            >
                <Box
                    width={400}
                    position="absolute"
                    zIndex={-1}
                    right={0}
                    mt={`-300px`}
                    sx={{
                        aspectRatio: "1/1",
                        overflow: "hidden"
                    }}

                >
                    <Box
                        sx={{
                            width: 400,
                            aspectRatio: "1/1",
                            bgcolor: orange[700],
                            // border: `5px solid ${orange[700]}`,
                            transform: `translateX(${window.innerWidth > 500 ? 200 : 300}px)`,
                            borderRadius: 400
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        width: 300,
                        aspectRatio: "1/1",
                        position: "absolute",
                        border: `10px solid ${green[700]}`,
                        zIndex: -1,
                        ml: '-150px',
                        mt: '300px',
                        borderRadius: 400
                    }}
                />
                <Typography
                    textAlign={"justify"}
                >
                    Les bénéficiaires directs sont estimés entre
                    <b> 2 et 2,3 millions de personnes. </b>
                    <br />
                    Ils comprennent les communautés rurales;
                    les populations vulnérables
                    (migrants agricoles impactés par le climat,
                    éleveurs transhumants, personnes déplacées à l’intérieur du pays,
                    jeunes hommes et femmes et personnes âgées); communes; les autorités traditionnelles/coutumières;
                    les services techniques de l’Etat aux niveaux central et déconcentré; les organisations de producteurs;
                    les Micro, petites et moyennes entreprises (MPME); les investisseurs privés intéressés par les Produits
                    forestiers non ligneux (PFNL) durables et la société civile et les groupes communautaires qui seront
                    impliqués dans la gouvernance des terres dégradées.
                </Typography>

                <Typography
                    level='h4'
                    textColor={green[900]}
                >
                    Principaux résultats attendus
                </Typography>

                <Item
                    text='Superficie des terres faisant l’objet de pratiques de gestion durable du paysage (CRI, Hectare [Ha])'
                />
                <Divider sx={{ bgcolor: green[700] }} />
                <Item
                    text='Personnes bénéficiant davantage des ressources naturelles, ventilées par sexe (hommes/femmes)'
                />
                <Divider sx={{ bgcolor: green[700] }} />
                <Item
                    text='Émissions nettes de gaz à effet de serre (CRI, tonnes de CO2/an)'
                />
                <Divider sx={{ bgcolor: green[700] }} />
                <Item
                    text='Bénéficiaires cibles ayant obtenu la note « Satisfait » ou plus pour les interventions du projet (sexe / âge).'
                />
                <Button
                    endDecorator={
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                    }
                    sx={{
                        bgcolor: orange[700],
                    }}
                >
                    Cliquez ici pour avoir une vue d'ensemble sur les indicateurs
                </Button>

            </Stack>

        </Stack>
    )
}

export default ResultatZone