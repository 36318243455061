import { Box, Button, Link, Stack, Typography } from '@mui/joy'
import { CardMedia } from '@mui/material'
import { IMAGE, PAGE_URL } from '../../constant'
import { blue, green, grey } from '@mui/material/colors'
import { useState } from 'react'
import CloseBtn from './CloseBtn'

const PlainNavBar = (
    {
        setshowNavBar,
        setcurrentPath,
        currentPath
    }:
        { setshowNavBar: any, setcurrentPath: any, currentPath: string }
) => {
    return (
        <Stack
            zIndex={10}
            p={1}
            position={"fixed"}
            bgcolor={"white"}
            top={0}
            left={0}
            right={0}
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
        >
            <Link
                textColor={green[800]}
            >
                <Stack
                    direction={"row"}
                    width={150}
                    fontWeight={700}
                >
                    <CardMedia
                        component={"img"}
                        src={IMAGE.logo}
                        sx={{
                            width: 50,
                            aspectRatio: "1/1"
                        }}
                    />
                    <Typography
                        fontSize={11}
                    >
                        Projet de Restauration des Terres Dégradées
                    </Typography>
                </Stack>
            </Link>

            {
                window.innerWidth > 600 && (
                    <Stack>
                        <Stack
                            direction={"row"}
                            fontWeight={700}
                        >
                            {
                                PAGE_URL.map((value, index) => (
                                    <Button
                                        variant='outlined'
                                        sx={{
                                            border: 0,
                                            width: 100,
                                            textAlign: "center",
                                            ":hover": {
                                                bgcolor: "transparent"
                                            },
                                            color: currentPath === value.nom ? green[800] : grey[800]
                                        }}
                                        onClick={() => {
                                            window.history.pushState(undefined, value.nom, `/${value.nom}`);
                                            setcurrentPath(value.nom);
                                        }}
                                    >
                                        {value.nom}
                                    </Button>
                                ))
                            }
                        </Stack>
                        <Box
                            sx={{
                                width: 30,
                                height: 10,
                                bgcolor: green[800],
                                borderRadius: 50,
                                ml: `${PAGE_URL.findIndex(value => value.nom == currentPath) * 100}px`,
                                transition: `all 0.5s`,
                                transform: `translateX(35px)`
                            }}
                        />
                    </Stack>
                )
            }

            <CloseBtn
                setshowNavBar={setshowNavBar}
                sx={null}
                showNavBar={true}
            />

        </Stack>
    )
}

export default PlainNavBar