import { faMapLocation } from "@fortawesome/free-solid-svg-icons";

export const MENU_ITEM = [
    {
        title: "Localité",
        icon: faMapLocation,
        item: [
            {
                nom: "Région"
            },
            {
                nom: "Cercle"
            },
            {
                nom: "Arrondissement"
            },
            {
                nom: "Commune"
            },
            {
                nom: "Localités"
            },
        ]
    }
];

export const IMAGE = {
    claraDeSousa: "/assets/images/Clara-de-Sousa.jpeg",
    carteDuMali: "/assets/images/carte-du-mali.png",
    fondTerre: "/assets/images/fondterre.png",
    logo: "/logo.png",
    republicDuMali: "/assets/images/republicDuMali.png",
    logoBanqueMondiale: "/assets/images/LOGO-WORLD-BANK.png",
    drapeauDuMali: "/assets/images/drapeauDuMali.png",
};

export const PAGE_URL = [
    {
        nom: "Accueil",
    },
    {
        nom: "Cartographie",
    },
    {
        nom: "Informations",
    },
];

export const LIENS_UTILES = [
    {
        nom: "Accueil",
        href: "Accueil",
    },
    {
        nom: "Cartographie",
        href: "Cartographie",
    },
    {
        nom: "Informations",
        href: "Informations",
    },
    {
        nom: "Presentation du projet",
        href: null,
    },
    {
        nom: "Courbe d'evolution des indicateurs",
        href: null,
    },
];

