import { faFilter, faSearch } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Divider, Input, LinearProgress, Stack } from "@mui/joy"
import { Collapse } from "@mui/material"
import { green } from "@mui/material/colors"
import { Fragment, useEffect, useState } from "react"
import IndicateurItem from "./indicateurItem"
import { LOADING_STATE_T, LOCATILTE_T, SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T } from "../../../../types"
import { suivi_annuel_sur_des_indicateurs_referentiels_par_localité } from "../../../../functions/API/suivi_annuel_sur_des_indicateurs_referentiels_par_localité"
import { region_tab } from "../../../../functions/api_scripts/region_par_indicateur"
import { localite } from "../../../../functions/API/localite"

const FilterZone = (
    { setindicateurData, setvaleurTotal }:
        {
            setindicateurData: any,
            setvaleurTotal: any
        }
) => {
    const [isOpen, setisOpen] = useState(true);
    const [indicateurListe, setindicateurListe] = useState([] as SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T[]);
    const [indicateurListeFiltered, setindicateurListeFiltered] = useState([] as SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T[]);
    const [loadingState, setloadingState] = useState(null as LOADING_STATE_T);
    const [currentIndicateur, setcurrentIndicateur] = useState(null as null | SUIVI_ANNUEL_SUR_DES_INDICATEURS_REFERENTIELS_PAR_LOCALITE_T);
    const [anneeListe, setanneeListe] = useState([] as any[]);
    const [currentAnnee, setcurrentAnnee] = useState(2024 as null | number);
    const [searchValue, setsearchValue] = useState(null as string | any);
    const [localiteListe, setlocaliteListe] = useState([] as LOCATILTE_T[]);

    useEffect(
        () => {
            suivi_annuel_sur_des_indicateurs_referentiels_par_localité().then(
                data => {
                    setindicateurListe(data);
                    setindicateurListeFiltered(data);
                    setcurrentIndicateur(data[0]);
                }
            ).finally(
                () => setloadingState("Chargement finit.")
            );

            localite().then(
                data => data && setlocaliteListe(data)
            )
        },
        []
    );

    useEffect(
        () => {
            currentIndicateur?.Valeur_Realisée && setanneeListe(
                Object.keys(currentIndicateur?.Valeur_Realisée)
            );
        },
        [currentIndicateur]
    );

    useEffect(
        () => {
            if (currentIndicateur && currentAnnee) {
                region_tab(
                    currentIndicateur?.Intitule,
                    currentAnnee,
                    currentIndicateur?.Code,
                    indicateurListe,
                    localiteListe.length < 1 ? undefined : localiteListe
                ).then(
                    data => {
                        setindicateurData(data.resultats);
                    }
                );

                setvaleurTotal(currentIndicateur.Valeur_Cible[currentAnnee])
            }
        },
        [currentIndicateur, currentAnnee]
    );

    useEffect(
        () => {
            if (searchValue) {
                setindicateurListeFiltered(indicateurListe.filter(value => value.Intitule.toLowerCase().includes(searchValue.toLowerCase())));
            }
            else {
                setindicateurListeFiltered(indicateurListe);
            }
        },
        [searchValue]
    )

    return (
        <Stack
            minWidth={330}
        >
            <Button
                endDecorator={
                    <FontAwesomeIcon icon={faFilter} />
                }
                fullWidth={false}
                sx={{
                    bgcolor: green[700],
                    borderRadius: '12px 12px 0 0',
                    width: "fit-content",
                }}
                onClick={() => setisOpen(!isOpen)}
            >
                Filtre d'indicateur
            </Button>

            <Collapse
                in={isOpen}
                sx={{
                    bgcolor: green[700],
                    borderRadius: "0 12px 12px 12px",
                    p: 1,
                }}
            >
                <Stack
                    gap={1}
                >
                    <Input
                        value={searchValue}
                        onChange={({ target }) => {
                            setsearchValue(target.value)
                        }}
                        endDecorator={
                            <FontAwesomeIcon icon={faSearch} />
                        }
                        placeholder="Rechercher un indicateur précis"
                    />

                    <Stack
                        bgcolor={green[700]}
                        direction={'row'}
                        gap={2}
                    >
                        <Stack
                            width={"100%"}
                            gap={1}
                            maxHeight={250}
                            pr={1}
                            sx={{
                                overflowY: 'scroll'
                            }}
                        >
                            {
                                loadingState != "Chargement finit."
                                    ? (
                                        <LinearProgress />
                                    )
                                    : indicateurListeFiltered.map((value, index) => (
                                        value.Valeur_Realisée && (
                                            <IndicateurItem
                                                key={index}
                                                value={value}
                                                setcurrentIndicateur={setcurrentIndicateur}
                                                isActive={value == currentIndicateur}
                                            />
                                        )
                                    ))
                            }
                        </Stack>

                        <Stack>
                            {
                                anneeListe.map((value, index) => (
                                    <Fragment>
                                        <Button
                                            sx={{
                                                bgcolor: value == currentAnnee ? 'white' : 'transparent',
                                                px: 1,
                                                color: 'black'
                                            }}
                                            onClick={() => {
                                                setcurrentAnnee(value);
                                            }}
                                        >{value}</Button>
                                        {
                                            index < anneeListe.length - 1 && (
                                                <Divider
                                                    sx={{
                                                        bgcolor: 'white'
                                                    }}
                                                />
                                            )
                                        }
                                    </Fragment>
                                ))
                            }
                        </Stack>
                    </Stack>
                </Stack>

            </Collapse>
        </Stack>
    )
}

export default FilterZone