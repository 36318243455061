import { Stack, Typography } from "@mui/joy"
import { CardMedia } from "@mui/material"
import { IMAGE } from "../../constant"
import { blue } from "@mui/material/colors"

const LogoZone = () => {
    return (
        <Stack
            maxWidth={350}
        >
            <Stack
                direction={"row"}
                gap={2}
                alignItems={"center"}
            >
                <CardMedia
                    component={"img"}
                    src={IMAGE.logo}
                    sx={{
                        width: 100,
                        aspectRatio: '1/1'
                    }}
                />

                <CardMedia
                    component={"img"}
                    src={IMAGE.republicDuMali}
                    sx={{
                        width: 100,
                        aspectRatio: '1/1'
                    }}
                />

                <Stack>
                    <CardMedia
                        component={"img"}
                        src={IMAGE.logoBanqueMondiale}
                        sx={{
                            width: 75,
                            aspectRatio: '1/1'
                        }}
                    />
                    <Typography
                        fontSize={8}
                        fontWeight={700}
                        textColor={blue[800]}
                        noWrap={true}
                        mt={-0.5}
                    >
                        LA BANQUE MONDIALE
                    </Typography>
                    <Typography
                        fontSize={8}
                        fontWeight={700}
                        textColor={blue[600]}
                        mt={-0.5}
                    >
                        BIRD . IDA
                    </Typography>
                </Stack>

            </Stack>

            <Typography
                textColor={"white"}
                mt={2}
                textAlign={"justify"}
            >
                Entièrement financé à hauteur de 90 milliards FCFA par la Banque mondiale, 
                le projet restauration des terres dégradées au Mali a 
                pour objectif d’accroître l’adoption de pratiques de 
                restauration des paysages intelligentes face au climat et l’accès à des 
                opportunités de revenus.
            </Typography>

        </Stack>

    )
}

export default LogoZone