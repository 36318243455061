import { Box, Stack, Typography } from "@mui/joy"
import { green } from "@mui/material/colors"
import FilterZone from "./FilterZone"
import { useEffect, useMemo, useState } from "react"
import { REGION_TAB_T } from "../../../types"
import GrapheZone from "./GarpheZone"
import RealisationZone from "./RealisationZone"

const IndicateurZone = () => {
    const [indicateurData, setindicateurData] = useState(null as null | REGION_TAB_T[]);
    const [valeurTotal, setvaleurTotal] = useState(0);

    const calculateValeurRealise = useMemo(() => () => {
        let res = 0;
        indicateurData?.forEach(({ valeurRealisee }) => {
            res += valeurRealisee;
        });

        return res;
    }, [indicateurData]);

    return (
        <Stack
            pt={3}
            gap={2}
        >
            <Typography>
                Une visualisation claire et efficace des indicateurs clés, permettant ainsi de mieux comprendre et analyser les données essentielles pour la prise de décision. Les indicateurs seront présentés de manière intuitive pour faciliter la comparaison et le suivi des performances, tout en offrant des perspectives précises sur les valeurs réelles et potentielles des données observées.
            </Typography>

            <Stack
                gap={1}
            >
                <Typography
                    level="title-lg"
                    component={'h2'}
                >
                    Statistique sur les indicateurs
                </Typography>
                <Box
                    sx={{
                        width: 50,
                        height: 10,
                        bgcolor: green[800],
                        borderRadius: 50,
                    }}
                />
            </Stack>

            <Stack
                direction={'row'}
                flexWrap={window.innerWidth < 800 ? "wrap" : "nowrap"}
                justifyContent={"space-between"}
                alignItems={"center"}
                gap={5}
            >
                <Stack
                    gap={2}
                >
                    <FilterZone
                        setindicateurData={setindicateurData}
                        setvaleurTotal={setvaleurTotal}
                    />

                    {
                        indicateurData && (
                            <RealisationZone
                                valeurRealise={calculateValeurRealise()}
                                valeurTotal={valeurTotal}
                            />
                        )
                    }
                </Stack>

                {
                    indicateurData && (
                        <GrapheZone
                            data={indicateurData}
                        />
                    )
                }
            </Stack>


        </Stack>
    )
}

export default IndicateurZone