import { Box, Card, CardContent, CardCover, Divider, Link, Stack, Typography } from "@mui/joy"
import { green, grey, orange } from "@mui/material/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuoteLeft, faQuoteRight } from "@fortawesome/free-solid-svg-icons"
import { CardMedia } from "@mui/material"
import Item from "./Item"
import { IMAGE } from "../../../constant"

const SoutientZone = () => {
    return (
        <Stack
            gap={2}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
            >
                <Typography
                    level="h3"
                    textColor={orange[700]}
                    sx={{
                        textWrap: "nowrap"
                    }}
                >
                    Soutients apportés au projet PRTD
                </Typography>
                <Box
                    height={2}
                    width={"100%"}
                    bgcolor={orange[700]}
                />
            </Stack>

            <Stack
                direction={"row"}
                flexWrap={window.innerWidth < 900 ? "wrap" : "nowrap"}
                gap={2}
                // alignItems={"center"}
                bgcolor={grey[200]}
                p={3}
                borderRadius={12}
            >
                <Stack
                    bgcolor={green[800]}
                    width="100%"
                    borderRadius={12}
                    overflow={"hidden"}
                >
                    <Card
                        sx={{
                            border: 0,
                            p: 0,
                            borderRadius: 0,
                            aspectRatio: '1/1'
                        }}
                    >
                        <CardCover>
                            <CardMedia
                                sx={{
                                    width: '100%',
                                    objectFit: "cover"
                                }}
                                component={"img"}
                                src={IMAGE.claraDeSousa}
                            />
                        </CardCover>
                        <CardCover
                            sx={{
                                background: `linear-gradient(180deg, rgba(3, 102, 24, 0) 80%, green)`,
                                opacity: 0.9
                            }}
                        />
                        <CardContent
                            sx={{
                                justifyContent: "flex-end",
                                alignItems: "center"
                            }}
                        >
                            <Typography
                                textColor={'white'}
                                level="h4"
                            >
                                Mme Clara De SOUSA
                            </Typography>
                            <Typography
                                textColor={'white'}
                            >
                                Directrice des Opérations de la Banque mondiale
                            </Typography>
                        </CardContent>
                    </Card>
                    <Typography
                        textColor={"white"}
                        // level="body-xs"
                        p={2}
                        textAlign={"justify"}
                    >
                        <FontAwesomeIcon
                            style={{
                                marginRight: 10,
                                color: orange[700],
                                fontSize: 40
                            }}
                            icon={faQuoteLeft}
                        />
                        <Typography>
                            Ce projet entre dans le cadre du plan d´action sur le changement
                            climatique 2021-2025 du Groupe de la Banque mondiale, dont l´objectif
                            est de soutenir les investissements transformateurs, de renforcer la
                            résilience des systèmes socio-économiques et d’améliorer les
                            conditions de vie des hommes, femmes et jeunes opérant sur des terres
                            et dans des environnements vulnérables aux chocs climatiques.
                        </Typography>
                        <Typography>
                            Il permettra au Mali d’avoir des informations supplémentaires
                            sur ses ressources à travers les plans d’aménagement forestiers,
                            pastoraux et ceux de la pêche qui seront élaborés et mis en œuvre.
                            Il s’agit d’une activité stratégique, structurante et innovante
                            à plusieurs égards
                        </Typography>
                        <FontAwesomeIcon
                            style={{
                                marginTop: -20,
                                transform: `translateY(15px)`,
                                marginLeft: 10,
                                color: orange[700],
                                fontSize: 40
                            }}
                            icon={faQuoteRight}
                        />
                    </Typography>
                </Stack>

                <Stack
                    gap={2}
                    sx={{
                        overflowX: "hidden",
                        lineHeight: 1.5,
                        color: grey[800]
                    }}
                >
                    <Typography
                        fontWeight={700}
                        level="title-lg"
                        textAlign={"justify"}
                    >
                        Le Projet de restauration des terres dégradées au Mali a été
                        entièrement financé par la Banque mondiale à hauteur de 90 milliards FCFA
                    </Typography>
                    <Typography
                        fontWeight={700}
                        level="body-md"
                        textAlign={"justify"}
                    >
                        La Banque mondiale aide le Mali à faire face à ses enjeux économiques
                        en mettant l’accent sur l’éducation, la santé, l’agriculture et l’énergie.
                    </Typography>

                    <Typography
                        level="body-sm"
                        textAlign={"justify"}
                    >
                        L’action de la Banque mondiale au Mali est déterminée par un cadre de partenariat
                        conçu pour faire face aux enjeux de l’après-crise de 2012 et 2020. Un nouveau
                        cadre de partenariat devant couvrir la période 2024-2026 est en cours d’élaboration.
                    </Typography>
                    <Typography
                        level="body-sm"
                        textAlign={"justify"}
                    >
                        L’Association internationale de développement (IDA), l’institution
                        du Groupe de la Banque mondiale qui aide les pays les plus pauvres, finance actuellement
                        <b> 14 projets nationaux et 10 projets régionaux au Mali pour un montant total d’un peu
                            plus de 1,8 milliard de dollars (dons et prêts inclus). </b>
                        Ces projets soutiennent le développement dans des secteurs tels que l’énergie,
                        l’agriculture, les routes, la protection sociale, l’eau et l’assainissement,
                        <Link
                            href="https://www.banquemondiale.org/fr/news/immersive-story/2019/09/18/coming-together-to-help-african-girls-create-a-brighter-future"
                            sx={{
                                ml: 1,
                                fontWeight: 700,
                                textDecorationLine: "underline"
                            }}
                        >
                            l’autonomisation des femmes</Link>
                        , l’éducation et la santé.
                    </Typography>

                    <Typography
                        level="h4"
                        textColor={orange[800]}
                    >
                        Société financière internationale (IFC)
                    </Typography>
                    <Typography
                        level="body-sm"
                        textAlign={"justify"}
                    >
                        L’IFC, le guichet du Groupe de la Banque mondiale pour le développement du secteur
                        privé met en place des projets au cœur du développement du Mali. En collaboration avec
                        la Banque mondiale et l’initiative régionale Sahel du Groupe de la Banque mondiale,
                        l’IFC facilite les réformes favorisant l’essor du secteur privé. Pour attirer
                        les investisseurs et réduire les risques liés à ces projets, elle apporte financement
                        et assistance technique.
                    </Typography>
                    <Typography
                        level="body-sm"
                        textAlign={"justify"}
                    >
                        Au 29 février 2024, les engagements de l’IFC au Mali
                        totalisaient 63.6 millions de dollars et comprenaient des investissements
                        dans les secteurs des finances, des infrastructures, des services et de l’agro-industrie.
                    </Typography>
                    <Typography
                        level="body-md"
                        textAlign={"justify"}
                    >
                        Au Mali, l’IFC mobilise le secteur privé pour soutenir la reprise économique et
                        la résilience du pays. Pour la période 2021-2025, la stratégie comprend
                        les priorités suivantes :
                    </Typography>

                    <Item
                        text="Appui à la réponse à la Covid-19 en soutenant le secteur bancaire et 
                        les institutions de microfinance"
                    />
                    <Divider sx={{ bgcolor: green[700] }} />
                    <Item
                        text="Restructuration et restauration de la viabilité du secteur privé pour 
                        réduire l’impact de la crise sur les entreprises du secteur réel et les institutions 
                        financières en mettant l’accent sur les investissements dans le secteur 
                        énergétique et en soutenant les réformes dans les télécoms et la finance digitale"
                    />
                    <Divider sx={{ bgcolor: green[700] }} />
                    <Item
                        text="Soutien à la relance et à la reconstruction de l’économie en encourageant 
                        les réformes pour améliorer l’environnement des affaires afin de dynamiser les 
                        investissements dans les infrastructures, dans l’agriculture et l’agro-industrie, 
                        promouvoir l’inclusion financière."
                    />
                </Stack>
            </Stack>

        </Stack>
    )
}

export default SoutientZone