import { Link, Stack, Typography } from "@mui/joy"
import { LIENS_UTILES } from "../../constant"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"

const LienUtileZone = () => {
    return (
        <Stack
            gap={2}
        >
            <Typography
                fontWeight={700}
                textColor={"white"}
                fontSize={20}
            >
                Liens utiles
            </Typography>

            <Stack
                sx={{
                    "& > a": {
                        color: "white",
                    }
                }}
                gap={1}
            >
                {
                    LIENS_UTILES.map(value => (
                        <Link
                            startDecorator={
                                <FontAwesomeIcon icon={faAngleRight} />
                            }
                            href={value.href as any}
                        >
                            {value.nom}
                        </Link>
                    ))
                }
            </Stack>
        </Stack>
    )
}

export default LienUtileZone