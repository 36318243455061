import { Box, Divider, Stack, Typography } from "@mui/joy"
import { green, grey, orange } from "@mui/material/colors"
import Item from "./Item"

const CadreExecutionZone = () => {
    return (
        <Stack
            gap={2}
        >
            <Stack
                direction={"row"}
                alignItems={"center"}
                gap={2}
            >
                <Typography
                    level="h3"
                    textColor={orange[700]}
                    sx={{
                        textWrap: "nowrap"
                    }}
                >
                    Cadre d'execution
                </Typography>
                <Box
                    height={2}
                    width={"100%"}
                    bgcolor={orange[700]}
                />
            </Stack>

            <Stack
                direction={"row"}
                flexWrap={window.innerWidth < 900 ? "wrap" : "nowrap"}
                gap={2}
                alignItems={"center"}
                bgcolor={grey[200]}
                p={3}
                borderRadius={12}
            >
                <Stack
                    gap={2}
                    sx={{
                        overflowX: "hidden",
                        lineHeight: 1.5,
                        color: grey[800]
                    }}
                >
                    <Item
                        text="Les modalités de mise en œuvre du PRTD-MALI sont élaborées sur la base d'une 
                        analyse comparative des modalités de mise en œuvre du PGRNCC, du PREEFN, du PAAR et du PRRE. 
                        L'agence de mise en œuvre du projet est le MEADD qui assurera la gestion
                        fiduciaire de l'ensemble des activités du projet."
                    />
                    <Divider sx={{ bgcolor: green[700] }} />
                    <Item
                        text="La mise en œuvre quotidienne des activités du projet sera assurée par une UGP directement 
                        rattachée au secrétaire général du ministère. 
                        L'UGP sera responsable de la mise en œuvre, 
                        de la gestion, de la coordination et du suivi et de l'évaluation du projet."
                    />
                    <Divider sx={{ bgcolor: green[700] }} />
                    <Item
                        text="Un comité de pilotage du projet (COPIL), 
                        présidé par le ministre du MEADD, 
                        sera composé des principales parties prenantes travaillant sur la gestion 
                        naturelle et le changement climatique. 
                        Le COPIL est l'organe de supervision et de validation des activités du projet."
                    />
                </Stack>
            </Stack>

        </Stack>
    )
}

export default CadreExecutionZone