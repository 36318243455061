import { Button, Link, Typography } from "@mui/joy"
import { green, grey } from "@mui/material/colors"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAngleRight } from "@fortawesome/free-solid-svg-icons"
import { Collapse } from "@mui/material"
import { Fragment } from "react/jsx-runtime"
import { useState } from "react"

const Composante = (
    { title, desc }:
        { title: string, desc: string }
) => {
    const [isShow, setisShow] = useState(false);

    return (
        <Fragment>
            <Button
                sx={{
                    alignSelf: "flex-start",
                    borderRadius: 30,
                    bgcolor: green[900],
                }}
                endDecorator={
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        style={{
                            transition: `all .5s`,
                            transform: `rotate(${isShow ? 90 : 0}deg)`
                        }}
                    />
                }
                onClick={() => setisShow(!isShow)}
            >{title}</Button>

            <Collapse
                in={isShow}
            >
                <Typography
                    p={1}
                    bgcolor={grey[300]}
                    borderRadius={12}
                >
                    {desc}
                    <Link>{` `}Voir plus</Link>
                </Typography>
            </Collapse>
        </Fragment>
    )
}

export default Composante