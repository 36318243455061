import { Container, Stack } from "@mui/joy"
import Header from '../../components/Header'
import PresentationZone from "./presentationZone"
import ZoneCible from "./zoneCible"
import CadreExecutionZone from "./cadreExecutionZone"
import ResultatZone from "./resultatZone"
import SoutientZone from "./soutientZone"

const Accueil = () => {

    return (
        <Stack
            gap={3}
            pb={10}
        >
            <Header />
            <Container>
                <Stack
                    gap={8}
                >
                    <PresentationZone />
                    <ZoneCible />
                    <CadreExecutionZone />
                    <ResultatZone />
                    <SoutientZone />
                </Stack>
            </Container>

        </Stack>
    )
}

export default Accueil